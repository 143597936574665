<template>
  <div style="padding: 24rem;height: 48rem;">
    <div style="height: 46rem;display: flex;align-items: center">
      <el-select v-model="value" placeholder="请选择任务名称" size="small" @change="onchangeValue">
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-button @click="onDownload" size="small" style="height: 32rem;margin:0 24rem;" type="primary" :disabled="disabled">
        <div style="display: flex;align-items: center">
          <img src="@/assets/img/export.png" alt="" style="width: 14rem;height: 14rem;margin-right: 4rem">
          <span>导出</span>
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
    return {
      value: '',
      disabled: true,
      options: []
    }
  },
  methods: {
    onchangeValue() {
      if (new Date() > new Date(this.options[this.options.findIndex(i => i.id == this.value)].start_at)) {
        this.disabled = false
      } else {
        this.$message.warning('当前任务暂未开始')
        this.disabled = true
      }
    },
    onDownload() {
      this.$_axios2.get('api/tool/new-subject/download?id=' + this.value).then(res => {
        const filename = '新高考选科' + '.csv';
        const blob = new Blob([res.data]);
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(filename);
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
  },
  created() {
    this.$_axios2.get('api/tool/new-subject/evaluation').then(res => {
      this.options = res.data.data
    })
  }
};
</script>

<style scoped>

</style>
